.popup {
  background: #FFFFFF;
  border-radius: 2px;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #191F2E;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}


.content {
  width: 100%;

  &__note {
    font-size: 12px;
    font-family: Kanit;
    color: var(--neutral-light-gra);
    opacity: 0.7;
    margin-bottom: 24px;

  }

  &__leadsLeft {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid rgba(230, 217, 248, 1);
    background-color: rgba(248, 243, 255, 1);
    border-radius: 6px;
    padding: 8px;

    &_text {
      font-family: Kanit;
      font-weight: 400;
      font-size: 16px;
      color: rgba(73, 43, 131, 1)
    }
  }
}

  

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}

