.popup {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  width: 564px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family-kanit);
}

.iconContainer {
  margin-bottom: 8px;
}

.title {
  font-family: var(--font-family-kanit);
  font-weight: bold;
  color: #492B83;
  font-size: 24px;
}

.description {
  font-family: var(--font-family-kanit);
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 300;
}

.featuresBox {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
}

.feature {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 300;
  font-family: var(--font-family-kanit);
  font-size: 14px;
}

.bullet {
  width: 8px;
  height: 8px;
  background-color: #492B83;
  border-radius: 50%;
  display: inline-block;
}

.priceContainer {
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
}

.oldPrice {
  text-decoration: line-through;
  color: grey;
  font-family: var(--font-family-kanit);
}

.newPrice {
  color: #492B83;
  font-weight: bold;
  font-family: var(--font-family-kanit);
  font-size: 24px;
}

.priceSubText {
  color: black;
  font-weight: 400;
  font-size: 18px;
  font-family: var(--font-family-kanit);
}

.priceInfo {
  color: grey;
  font-family: var(--font-family-kanit);
  font-size: 14px;
}

.benefitsContainer {
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
}

.benefit {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--font-family-kanit);
  font-size: 14px;
}

.checkIcon {
  color: green;
}