.popup {
  background: #FFFFFF;
  border-radius: 4px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
}

.header {
  width: 100%;
  border: 0.5px solid #c7d1e5;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  gap: 6px;
  overflow: hidden;

  &__typography {
    font-family: 'Kanit';
    font-weight: 400;
    font-size: 14px;
    color: #191F2E;
    letter-spacing: 0;
    line-height: 110%;
    text-align: justify;
  }

  &__span {
    font-family: 'Kanit';
    line-height: 110%;
    font-size: 14px;
    color: rgba(73, 43, 131, 1);
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  &__title {
    color: #6f7d9b;
    font-family: 'Kanit';
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 12px;
  }
  &__report {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__info {
      font-family: 'Kanit';
      font-weight: 400;
      font-size: 16px;
      color: #191F2E;
    }

    &__number {
      font-family: 'Kanit';
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 170, 37, 1);
      &_red {
        color: rgba(216, 60, 20, 1);
      }
    }
  }
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Kanit';
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 18px;
    &_bold {
      font-weight: 400;
      margin-left: 6px;
      margin-right: 2px;
    }
  }
}  

.footer {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-top: 12px;
  align-items: center;
}

