.popup {
  background: #FFFFFF;
  border-radius: 4px;
  width: 542px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  &__title {
    font-family: Kanit;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: rgba(25, 31, 46, 1);
    &_red{
      color: rgba(219, 73, 31, 1)
    }
  }

  &__closeButton {
    position: absolute;
    right: -150px;
    top: -10px;
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}

.content {
  width: 100%;
  &__mainText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Kanit;
    font-weight: 400;
    font-size: 16px;
    line-height: 110.00000000000001%;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 8px; 
    margin-bottom: 24px;  
  }

  &__videoContainer {
    border-radius: 8px;
  }

  &__iframe {
    border-radius: 8px;
  }
}

  

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}

