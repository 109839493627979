.popup {
  background: #FFFFFF;
  border-radius: 4px;
  width: 542px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    font-family: var(--font-family-kanit);
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}

.content {
  &__subTitle {
    font-family: var(--font-family-kanit);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 24px;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  &__action {
    display: flex;
    font-weight: 300;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    height: 40px;
    gap: 8px;
    box-shadow: none;
    font-family: var(--font-family-kanit);
    border-radius: 2px;
    background-color:#3B2470;
    color: #fff;
    text-decoration: none;
    margin-bottom: 12px;
    width: 140px;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 6px 20px rgba(0, 0, 0, 0.19);
    }
  }
  
}

.popupSuccess {
  background: #FFFFFF;
  border-radius: 4px;
  width: 542px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
}

.headerSuccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__img {
    margin-top: 24px;
  }

  &__title {
    font-family: var(--font-family-kanit);
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    padding: 24px 32px;
  }

  &__subTitle {
    font-family: var(--font-family-kanit);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding: 12px 32px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

