.indicatorWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  font-family: Kanit;
  box-shadow: none;
  border-radius: 4px;
}

.actionButton {
  display: flex;
  padding: 6px 12px;
  gap: 4px;
  background-color: #fff;
  &:hover {
    background-color: #fff,
  }
}