.content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    text-align: center;
    margin-bottom: 24px;
    position: relative;

    &__close {
        position: absolute;
        right: -25px;
        top: -25px;
    }

    &__mainTitle {
        font-size: 18px;
        font-family: Kanit;
        font-weight: 500;
        padding: 6px;
        padding-bottom: 0;
    }

    &__subTitle {
        font-size: 16px;
        font-family: Kanit;
        font-weight: 300;
    }
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    &__badge {
        font-family: Kanit;
        font-weight: 300;
        font-size: 16px;
        line-height: 110.00000000000001%;
        letter-spacing: 0%;
        text-align: center;
        margin-top: 12px;
    }
    &__element {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background-color: #c7d1e5;
        &_current {
            background-image: url('../../../public/logo_small.svg');
            background-color: transparent;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &_passed {
            background-color: #492b83;
            opacity: 0.8;
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        margin-top: 32px;
    }
}


