.popup {
  background: #FFFFFF;
  border-radius: 8px;
  max-width: 1050px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 44px 64px;
  gap: 24px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__loader {
    color: rgba(59, 36, 112, 1);
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__title {
    font-family: Kanit;
    font-size: 28px;
    font-weight: bold;
    color: #492B83;
  }

  &__subTitle {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.36px;
    color: rgba(25, 31, 46, 1);
    margin-bottom: 8px;

  }

  &__closeButton {
    position: absolute;
    width: 20px;
    height: 20px;
    overflow: hidden;
    right: -45px;
    top: -20px;
  }
}


.content {
  width: 100%;
  display: flex;
  gap: 18px;

  &__card {
    border-radius: 12px;
    padding: 18px;
    flex: 1;      
    min-width: 0;

    &__bestDeal{
      position: relative;
      border: 1px solid rgba(109, 48, 183, 1);
      &::before {
        content: "Best Deal";
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%); 
        background-color: rgba(228, 217, 246, 1);
        color: #492B83;
        padding: 10px 35px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Kanit';
      }
    }
    &__current{
    }
  }

  &__cardHeader {
    display: flex;
    align-items: center;
    gap: 8px;

    &_cardNameContainer {
      display: flex;
      flex-direction: column;      
    }

    &_cardName {
      font-family: Kanit;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.0;
      margin-bottom: 10px;
      color: #492B83;
    }
    &_cardDescriptionWrapper {
      height: 50px;
    }
    &_cardDescription {
      font-family: Inter;
      line-height: normal;
      font-size: 13px;
      font-weight: 500;
      color: #492B83;
    }

    &_cardLeads {
     font-family: Kanit;
      font-size: 18px;
      font-weight: 400;
      color: rgba(111, 125, 155, 1);

      &_main {
        font-size: 20px;
        font-weight: bold;
        color: black;
      }

      &_sub {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
        color: gray;
      }
    }

  }

  &__cardPriceContainer {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    gap: 6px;
    align-items: center;

    &_cardPriceMain {
      font-family: Kanit;
      font-size: 32px;
      font-weight: 600;
      align-self: flex-end;
      line-height: 1;
      display: flex;
      align-items: center;
      color: #492B83;
    }
    &_cardPriceSyb {
      font-family: Kanit;
      font-size: 16px;
      font-weight: 400;
      align-self: flex-end;
    }
  }

  &__actionsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &_button {
      box-shadow: none;
      width: 300px;
      height: 30px;
      padding: 10px 16px 10px 16px;
      font-family: var(--font-family-kanit);
      font-size: 14px;
      &_trial{
        background-color: #FFFFFF;
        color: #492B83;
        border: 2px solid rgba(109, 48, 183, 1);
        &:hover {
          background-color: #F8F3FF;
        }
      }
      &_current {
        background-color: rgba(242, 242, 242, 1);
        color: var(--neutral-back);
        &:hover { 
          background-color: rgba(242, 242, 242, 1);
        }
        &:disabled {
          background-color: rgba(242, 242, 242, 1);
          color: var(--neutral-back);
          opacity: 0.6;
        }
      }
      &_regular {
        background-color: #492B83;
        color: #fff;
        &:hover { 
          background-color: #492B83;
        }
        &:disabled {
          background-color: #492B83;
          color: #fff;
          opacity: 0.6;
        }
      }
    }
  }

&__featuresContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &_feature {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
  }

  &_featureLeads {
    font-weight: 400;
  }
}

&__specialfeaturesContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 2px;
  border-radius: 8px;
}


}

