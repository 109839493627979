.popup {
  background: #FFFFFF;
  border-radius: 2px;
  width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #191F2E;
  }

  &__closeButton {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
  }
}


.content {
  width: 100%;

  &__title {
    font-family: Kanit;
    font-weight: 300;
    font-size: 16px;

    &_bold {
      font-weight: 500;
    }
  }

  &__upload {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    &__info {
      display: flex;
      flex-direction: column;

      &_title {
        font-family: Kanit;
        font-weight: 400;
        font-size: 16px;
        color: rgba(25, 31, 46, 1);
        
      }
      &_size {
        font-family: Kanit;
        font-weight: 300;
        font-size: 14px;
      }
    }

    &__actions {
      &_fileName {
        font-family: Kanit;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  &__leadsLeft {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid rgba(230, 217, 248, 1);
    background-color: rgba(248, 243, 255, 1);
    border-radius: 6px;
    padding: 8px;
    &_container {
      display: flex;
      flex-direction: column;
    }

    &_text {
      font-family: Kanit;
      font-weight: 400;
      font-size: 16px;
      color: rgba(73, 43, 131, 1);
    }

    &_limit {
      // margin-top: 10px;
      font-family: Kanit;
      color: rgba(73, 43, 131, 1);
      opacity: 0.7;
      font-size: 12px;
    }
  }

  &__noUploadedFile {
    height: 39px;
    margin-top: 12px;
  }

  &__uploadedFile {
    height: 39px;
    margin-top: 12px;
    border: 0.5px solid rgba(200, 200, 200, 1);
    padding: 8px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__file {
      display: flex;
      align-items: center;
      gap: 6px;
      &_name {
        font-family: Kanit;
        font-weight: 300;
        font-size: 14px;
        color: rgba(109, 109, 109, 1);
      }

    }
  }

  &__download {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  &__downloadText {
    font-family: 'Kanit';
    font-size: 14px;
    font-weight: 300;
  }

  &__downLoadButton {
    display: flex;
    gap: 6px;
  }

  &__downLoadButtonText {
    font-family: 'Kanit';
    font-size: 14px;
    font-weight: 400;
    color: #3B2470;
  }
}  

.footer {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__action {
    color: white;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 143px;
    height: 40px;
    background: #3B2470;
    border-radius: 1px;
    box-shadow: none;
    font-family: var(--font-family-kanit);
    &:hover {
      background: #3B2470;
    }

    &:disabled {
      background: #3B2470;
      color: white;
      opacity: 0.6;
    }
  }

  &__cancel {
    padding: 8px 16px;
    gap: 8px;
    width: 55px;
    height: 27px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6F7D9B;
  }
}

.contentSuccess {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__img {
    margin-top: 24px;
  }

  &__title {
    font-family: var(--font-family-kanit);
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    padding: 24px 32px;
  }

  &__unvalidLinks {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
  }

  &__link {
    font-family: Kanit;
    display: flex;
    padding: 2px;
    gap: 6px;
  }

  &__linkContent {
    display: flex;
    gap: 4px;
  }

  &__property {
    color: var(--neutral-back)
  }

  &__defenition {
    color: var(--neutral-gray)
  }

  &__subTitle {
    font-family: var(--font-family-kanit);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding: 12px 32px;
    margin-bottom: 24px;
  }
}

