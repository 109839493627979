.usersStatistic {
  &__pageContainer {
    width: 100%;
    // height: fit-content;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 24px;
    display: grid;
    gap: 24px;
    grid-template-areas:
    "header header header header"
    "general general general general"
    "diagrams diagrams diagrams reactions"
    "diagrams diagrams diagrams connections";
    // display: flex;
    // flex-wrap: wrap;
    @media (max-width: 1400px) {
      grid-template-areas:
      "header header header header"
      "reactions reactions reactions reactions"
      "general general general connections"
      "general general general connections"
      "diagrams diagrams diagrams diagrams"
    }

    @media (max-width: 1280px) {
      grid-template-areas:
      "header header header header"
      "reactions reactions reactions reactions"
      "general general general general"
      "connections connections connections connections"
      "diagrams diagrams diagrams diagrams"
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    &__mainTitle {
      font-family: Kanit;
      font-size: 32px;
      font-weight: 500;   
    }
    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }

  &__generalStatisticList {
    grid-area: general;
    // margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // @media (max-width: 1440px) {
    //   grid-template-columns: 2fr 2fr;
    // }
    &__card {
      flex-grow: 1;
      width: 250px;
      background-color: rgba(224, 232, 249, 0.4);
      padding: 20px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media (max-width: 1400px) {
        width: 260px;        
      }
      @media (max-width: 1280px) {
        width: 380px;      
      }
      &_wideSidebar {
        @media (max-width: 1400px) {
          width: 220px;        
        }
        @media (max-width: 1280px) {
          width: 350px;      
        }
      }

    }

    &__imgContainer {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(59, 36, 112, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__cardTitle {
      font-family: Kanit;
      font-size: 18px;
      font-weight: 400;
      margin-top: 12px;
    }

    &__cardValue {
      margin-top: 8px;
      font-family: Kanit;
      font-size: 36px;
      font-weight: 400;
    }

    &__cardDescriptiobn {
      font-family: Kanit;
      font-size: 16px;
      font-weight: 400;
      color: rgba(179, 187, 202, 1);
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__diagramsContainer {
    grid-area: diagrams;
    width: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: 1550px) {
      flex-direction: column;        
    }
    @media (max-width: 1400px) {
      flex-direction: row;       
    }
    @media (max-width: 1300px) {
      flex-direction: column;       
    }
  }

  &__diagramsResponsiveContainer {
    height: 360px;
    width: 100%;
  }

  &__emptyDiagrams {
    font-family: Kanit;
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
  }

  &__diagramsLoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__diagrams {
    flex-grow: 1;
    border-top: 1px solid rgba(224, 232, 249, 1);
    border-bottom: 1px solid rgba(224, 232, 249, 1);
    border-left: 1px solid rgba(224, 232, 249, 1);
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1550px) {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-right: 1px solid rgba(224, 232, 249, 1);
      border-top-right-radius: 8px;      
    }
    @media (max-width: 1400px) {
      border-bottom: 1px solid rgba(224, 232, 249, 1);
      border-bottom-left-radius: 8px;
      border-right: none;
      border-top-right-radius: 0;       
    }
    @media (max-width: 1300px) {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-right: 1px solid rgba(224, 232, 249, 1);
      border-top-right-radius: 8px;    
    }
    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 6px;
      margin-bottom: 24px;
      &__title {
        font-family: Kanit;
        font-size: 24px;
        font-weight: 400;
      }
      &__actions {
        display: flex;
        gap: 4px;
      }
    }
  }

  &__campaignList {
    width: 220px;
    min-height: 100px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1px solid rgba(224, 232, 249, 1);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;
    padding: 12px 24px;

    @media (max-width: 1550px) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      border-top-right-radius: 0;       
    }
    @media (max-width: 1400px) {
      width: 407px;
      flex-direction: column;
      border-top-right-radius: 8px;       
    }
    @media (max-width: 1300px) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      border-top-right-radius: 0;    
    }
  }

  &____campaignList::-webkit-scrollbar {
    display: none;
  }

  &__campaignListItem {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }


  &__reactions {
    grid-area: reactions;
    background-color: rgba(59, 36, 112, 1);
    padding: 24px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1400px) {
      height: 170px;
    }

    &__titleContainer {
      margin-left: 4px;
      display: flex;
      align-items: center;
      gap: 12px;
      // height: 50px;
    }

    &__title {
      position: relative;
      font-family: Kanit;
      font-size: 24px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      z-index: 2;
    }

    &__linkContainer {
      position: relative;
      z-index: 2;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(109, 48, 183, 1);
      margin-left: 8px;
      transition: opacity 0.2s;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }

    &__valueDescriptionContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (max-width: 1400px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }

    }

    &__value {
      position: relative;
      font-family: Kanit;
      font-size: 50px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      z-index: 2;
    }

    &__description {
      position: relative;
      font-family: Kanit;
      font-size: 16px;
      font-weight: 400;
      color: rgba(187, 194, 209, 1);
      z-index: 2;
      width: 230px;
    }

    &__bg {
      position: absolute;
      width: 300px;
      height: 420px;
      top: -60px;
      left: 110.46px;
      z-index: 1;

      @media (max-width: 1400px) {
        top: -80px;
        left: 960.46px;
      }
      @media (max-width: 1280px) {
        top: -80px;
        left: 850.46px;
      }
      @media (max-width: 1080px) {
        top: -80px;
        left: 650.46px;
      }
      @media (max-width: 768px) {
        top: -80px;
        left: 345.46px;
      }

      &_wideSidebar {
        @media (max-width: 1400px) {
          top: -80px;
          left: 778.46px;
        }
        @media (max-width: 1280px) {
          top: -80px;
          left: 658.46px;
        }
        @media (max-width: 1080px) {
          top: -80px;
          left: 465.46px;
        }
        @media (max-width: 768px) {
          top: -80px;
          left: 180.46px;
        }
      }
    }
  }

  &__connections {
    grid-area: connections;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    border: 1px solid rgba(224, 232, 249, 1);
    border-radius: 8px;
    padding: 24px;
    &__title {
      font-family: Kanit;
      font-size: 24px;
      text-align: left;
      @media (max-width: 1400px) {
        text-align: center;
      }
    }
    &__dataContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    &__totalContainer {
      position:absolute;
      top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__totalTitle {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: rgba(111, 125, 155, 1);
    }

    &__totalCount {
      font-family: Inter;
      font-size: 40px;
      font-weight: 700;
    }
  }
  
}


.toolTipBody {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

