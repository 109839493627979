.auth {
   display: flex;
   height: 100vh;
   
   &__popupSuccess {
    position: fixed;
    top: 36%;
    left: 52%;
    background: #FFFFFF;
    border-radius: 4px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    z-index: 1000;

    &__headerSuccess {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__img {
          margin-top: 24px;
        }
      
        &__title {
          font-family: var(--font-family-kanit);
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          padding: 24px 32px;
        }
      
        &__subTitle {
          font-family: var(--font-family-kanit);
          font-size: 16px;
          font-weight: 300;
          text-align: center;
          padding: 12px 32px;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
  }

   &__actions {
    background-color: #191F2E;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 48px;
   }

   &__background {
    width: 50%;
    overflow: hidden;
    &__img {
        width: 100%;
        height: 100%;
        filter: brightness(50%);
    }
   }

   &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__mainLogo {
        &__logo {
            margin-right: 12px;
        }

    }

    &__mainTitle {
        margin-top: 24px;
        font-family: Kanit;
        font-weight: 300;
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
    }    
   }

   &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    &__inputWrapper {
        height: 95px;
        display: flex;
        flex-direction: column;
    }

    &__label {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        padding-bottom: 5px;
        opacity: 0.7;
    }

    &__labelRestore {
        margin-top: 18px;
        display: flex;
        width: 468px;
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        justify-content: flex-end;
        align-items: center;
    }

    &__restoteBtn {
        padding: 2px;
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #191F2E;
        background-color: #E6D9F8;
        text-decoration: none;
        margin-left: 6px;
        &:hover {
            text-decoration: none;
            background-color: #E6D9F8;
            color: rgba(109, 48, 183, 1);
            box-shadow: #E6D9F8;
        }
    }
   }

   &__footer {
    &__text {
        margin-left: 48px;
        font-family: Inter;
        font-weight: 400;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
    }
   }
}


  

  

input:-webkit-autofill {
    appearance: none !important;
    background-image: none !important;
    background-color: transparent !important;
    color: inherit !important;
}

